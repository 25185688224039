import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'

import { ThemeProvider } from '@mui/material'

import AppTheme from '@shared/design'

import FtpLogin from './FtpLogin'

const HubFtpLogin = () => {
  return (
    <ThemeProvider theme={AppTheme}>
      <FtpLogin />
    </ThemeProvider>
  )
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: HubFtpLogin,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null
  },
})

export const { bootstrap, mount, unmount } = lifecycles
