import { User } from '../api/src'

export const testUserLotsAndSuperUser: User = {
  id: '11111111-1111-1111-1111-111111111111',
  domain_id: 'domain-11111111111111111',
  contact_id: 'contact-1111111111111111',
  username: 'mickey.mouse@fortispay.com',
  email: 'mickey.mouse@fortispay.com',
  locale: 'en-US',
  tz: 'America/New_York',
  login_attempts: 0,
  current_login_ip: '68.101.143.167',
  requires_new_password: '',
  created_ts: 1347746338,
  modified_ts: 1696527687,
  primary_location_id: '11111111-1111-1111-1111-111111111111',
  sftp_access: true,
  created_user_id: '11111111-1111-1111-1111-111111111111',
  terms_accepted_ts: null,
  terms_agree_ip: null,
  current_date_time: '2023-11-14T15:35:39-0500',
  ui_prefs: {
    entry_page: 'virtualterminal',
    page_size: 15,
    report_export_type: 'csv',
    process_method: 'physical_terminal',
    default_terminal: '11ed65d2b44fec1aab3427d8',
  },
  user_api_key: 'f...1',
  user_hash_key: 'b...7',
  log_api_response_body_ts: null,
  api_only: false,
  user_type_code: 1000,
  status_code: 1,
  terms_condition_code: null,
  location_id: '11111111-1111-1111-1111-111111111111',
  account_number: '9876543210',
  contact_api_id: null,
  company_name: 'OLU test',
  first_name: 'Minnie',
  last_name: 'Mouser',
  address: {
    street: 'Goobs Street',
    city: 'Manns',
    state: 'OH',
    postal_code: '567890',
    country: 'US',
  },
  home_phone: null,
  cell_phone: '1234567890',
  office_phone: null,
  email_trx_receipt: true,
  date_of_birth: '1990-05-15',
  header_message: '',
  active: true,
  contact_c1: null,
  contact_c2: null,
  contact_c3: null,
  parent_id: null,
  office_phone_ext: null,
  header_message_type: 0,
  balance: null,
  locations: [
    {
      id: 'location-222222222222222',
      location_api_id: 'location-222222222222222',
      name: 'Demo Account2',
      office_phone: '2482138999',
      office_ext_phone: '4321',
      fax: '0000000000',
      account_number: '1234567890',
      ticket_hash_key: 'ticket-22222222222222222',
      tz: 'America/New_York',
      created_ts: 1392066931,
      modified_ts: 1417445402,
      location_api_key: 'location-222222222222222',
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11111111-1111-1111-1111-111111111111',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '54aa9ab2-f912-8f38-d9d1-2246649884cc',
      show_contact_notes: true,
      default_ach: null,
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: 'addr2',
        city: 'Metropolis',
        state: 'NY',
        postal_code: '90210',
        country: 'US',
      },
    },
    {
      id: '11ed3e946f2c17e08857bfb1',
      location_api_id: null,
      name: 'Location Calder',
      office_phone: null,
      office_ext_phone: null,
      fax: null,
      account_number: '12345',
      ticket_hash_key: null,
      tz: 'America/New_York',
      created_ts: 1664304275,
      modified_ts: 1668619378,
      location_api_key: null,
      email_reply_to: null,
      branding_domain_id: '11ed3e946f0b2ba2b74248e4',
      branding_domain_url: 'test.zeamster.com',
      parent_id: '',
      location_c1: 'e1234567',
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '11ed65d4cae7cbbcc0b1ba0f',
      show_contact_notes: true,
      default_ach: '11ed40c9a85d444eac4726e3',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: '',
        city: 'Atlanta',
        state: 'GA',
        postal_code: '30339',
        country: 'US',
      },
    },
    {
      id: '11ee2d683e9457be9260359a',
      location_api_id: null,
      name: 'OLUTest Retail 9xib8',
      office_phone: null,
      office_ext_phone: null,
      fax: null,
      account_number: null,
      ticket_hash_key: null,
      tz: 'America/New_York',
      created_ts: 1690563624,
      modified_ts: 1690567499,
      location_api_key: null,
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11111111-1111-1111-1111-111111111111',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: true,
      default_cc: '11ee2d6840fbefeeb11775b2',
      show_contact_notes: true,
      default_ach: '11ee2d6841b944f488c224fb',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: null,
        street2: null,
        city: null,
        state: null,
        postal_code: null,
        country: 'US',
      },
    },
    {
      id: '11111111-1111-1111-1111-111111111111',
      location_api_id: '11111111-1111-1111-1111-111111111111',
      name: 'Demo Account',
      office_phone: '2482138999',
      office_ext_phone: '4321',
      fax: '0000000000',
      account_number: '1234567890',
      ticket_hash_key: 'ticket-11111111111111111',
      tz: 'America/New_York',
      created_ts: 1392066931,
      modified_ts: 1693482076,
      location_api_key: '11111111-1111-1111-1111-111111111111',
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11ed3e946f2c17e08857bfb1',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '11ed3e946f2c17e08857abc4',
      show_contact_notes: true,
      default_ach: '11e608a7d515f1e093242bb2',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: 'addr2',
        city: 'Metropolis',
        state: 'NY',
        postal_code: '123',
        country: 'US',
      },
    },
  ],
  branding_domain_url: 'test.develop.zeamster.com',
  module_access: [1],
  resources: {
    title: 'resources',
    id: 123,
    resource_name: 'resources',
    '1': {
      id: 1,
      title: 'Superuser',
      priv: '',
      resource_name: '*',
    },
    '5031': {
      id: 5031,
      title: 'Invoices Delete',
      priv: '',
      resource_name: 'v2.invoices.delete',
    },
    '5032': {
      id: 5032,
      title: 'Invoices Get',
      priv: '',
      resource_name: 'v2.invoices.get',
    },
    '5033': {
      id: 5033,
      title: 'Invoices Post',
      priv: '',
      resource_name: 'v2.invoices.post',
    },
    '5034': {
      id: 5034,
      title: 'Invoices Put',
      priv: '',
      resource_name: 'v2.invoices.put',
    },
    '5348': {
      id: 5348,
      title: 'v2admin users get',
      priv: null,
      resource_name: 'v2admin.users.get',
    },
    '5350': {
      id: 5350,
      title: 'v2admin users delete',
      priv: null,
      resource_name: 'v2admin.users.delete',
    },
    '5351': {
      id: 5351,
      title: 'v2admin locations post',
      priv: null,
      resource_name: 'v2admin.locations.post',
    },
    '5352': {
      id: 5352,
      title: 'v2admin locations get',
      priv: null,
      resource_name: 'v2admin.locations.get',
    },
    '5353': {
      id: 5353,
      title: 'v2admin locations put',
      priv: null,
      resource_name: 'v2admin.locations.put',
    },
    '5354': {
      id: 5354,
      title: 'v2admin locations delete',
      priv: null,
      resource_name: 'v2admin.locations.delete',
    },
    '5389': {
      id: 5389,
      title: 'v2 terminalaccountvaults get',
      priv: null,
      resource_name: 'v2.terminalaccountvaults.get',
    },
    '5390': {
      id: 5390,
      title: 'v2 terminalaccountvaults put',
      priv: null,
      resource_name: 'v2.terminalaccountvaults.put',
    },
    '5391': {
      id: 5391,
      title: 'v2 terminalaccountvaults post',
      priv: null,
      resource_name: 'v2.terminalaccountvaults.post',
    },
    '5392': {
      id: 5392,
      title: 'v2 terminalaccountvaults delete',
      priv: null,
      resource_name: 'v2.terminalaccountvaults.delete',
    },
    '5546': {
      id: 5546,
      title: 'v2 productitems post o',
      priv: null,
      resource_name: 'v2.productitems.post.o',
    },
    '5547': {
      id: 5547,
      title: 'v2 productitems post m',
      priv: null,
      resource_name: 'v2.productitems.post.m',
    },
    '5548': {
      id: 5548,
      title: 'v2 productitems post a',
      priv: null,
      resource_name: 'v2.productitems.post.a',
    },
    '5549': {
      id: 5549,
      title: 'v2 productitems post p',
      priv: null,
      resource_name: 'v2.productitems.post.p',
    },
    '5550': {
      id: 5550,
      title: 'v2 productitems post s',
      priv: null,
      resource_name: 'v2.productitems.post.s',
    },
    '5551': {
      id: 5551,
      title: 'v2 productitems put o',
      priv: null,
      resource_name: 'v2.productitems.put.o',
    },
    '5552': {
      id: 5552,
      title: 'v2 productitems put m',
      priv: null,
      resource_name: 'v2.productitems.put.m',
    },
    '5553': {
      id: 5553,
      title: 'v2 productitems put a',
      priv: null,
      resource_name: 'v2.productitems.put.a',
    },
    '5554': {
      id: 5554,
      title: 'v2 productitems put p',
      priv: null,
      resource_name: 'v2.productitems.put.p',
    },
    '5555': {
      id: 5555,
      title: 'v2 productitems put s',
      priv: null,
      resource_name: 'v2.productitems.put.s',
    },
    '5556': {
      id: 5556,
      title: 'v2 productitems delete o',
      priv: null,
      resource_name: 'v2.productitems.delete.o',
    },
    '5681': {
      id: 5681,
      title: 'v2 transactions get split',
      priv: null,
      resource_name: 'v2.transactions.get.split',
    },
    '5767': {
      id: 5767,
      title: 'v1 partner commissions get',
      priv: null,
      resource_name: 'v1.partner.commissions.get',
    },
    '5768': {
      id: 5768,
      title: 'v1 partner commissions get download',
      priv: null,
      resource_name: 'v1.partner.commissions.get.download',
    },
    '5838': {
      id: 5838,
      title: 'v2 merchants get',
      priv: null,
      resource_name: 'v1.partner.merchants.get',
    },
    '5839': {
      id: 5839,
      title: 'v2 merchants get export',
      priv: null,
      resource_name: 'v1.partner.merchants.get.export',
    },
    '5105': {
      id: 5105,
      title: 'v2.userprefs.get - User Prefs Get',
      priv: '',
      resource_name: 'v2.userprefs.get',
    },
    '5153': {
      id: 5153,
      title: 'v2.useruserprefs.get - User User Preferences Get',
      priv: '',
      resource_name: 'v2.useruserprefs.get',
    },
    '5154': {
      id: 5154,
      title: 'v2admin.tokenimportmappings.get - Token Import Mappings',
      priv: '',
      resource_name: 'v2admin.tokenimportmappings.get',
    },
    '5155': {
      id: 5154,
      title: 'v2admin.tokenimports.get - Token Import',
      priv: '',
      resource_name: 'v2admin.tokenimports.get',
    },
    '5156': {
      id: 5154,
      title: 'v2admin.tokenimports.post - Token Import',
      priv: '',
      resource_name: 'v2admin.tokenimports.post',
      created: '2015-03-24 12:00:00',
      last_used_date: null,
      created_ts: 1427198400,
      created_user_id: null,
      modified_ts: 1427198400,
      modified_user_id: null,
    },
    '5157': {
      id: 5154,
      title: 'v2admin.tokenimports.post.process - Token Import',
      priv: '',
      resource_name: 'v2admin.tokenimports.post.process',
      created: '2015-03-24 12:00:00',
      last_used_date: null,
      created_ts: 1427198400,
      created_user_id: null,
      modified_ts: 1427198400,
      modified_user_id: null,
    },
    '5158': {
      id: 5158,
      title: 'v2.routertransactions.post',
      priv: null,
      resource_name: 'v2.routertransactions.post',
    },
    '5159': {
      id: 5159,
      title: 'v2.terminaltransactions.post',
      priv: null,
      resource_name: 'v2.terminaltransactions.post',
    },
    '5160': {
      id: 5160,
      title: 'v2admin.tokenimportdetails.get',
      priv: null,
      resource_name: 'v2admin.tokenimportdetails.get',
    },
    '5161': {
      id: 5161,
      title: 'v2.accountvaults.token_import_id.post',
      priv: null,
      resource_name: 'v2.accountvaults.token_import_id.post',
    },
    '5162': {
      id: 5162,
      title: 'v2.producttransactiondetails.get',
      priv: null,
      resource_name: 'v2.producttransactiondetails.get',
    },
    '5163': {
      id: 5163,
      title: 'v2.quickinvoicesettings.put',
      priv: null,
      resource_name: 'v2.quickinvoicesettings.put',
    },
    '5164': {
      id: 5164,
      title: 'v2.producttransactiondetails.put',
      priv: null,
      resource_name: 'v2.producttransactiondetails.put',
    },
    '5165': {
      id: 5165,
      title: 'v2 tags post',
      priv: null,
      resource_name: 'v2.tags.post',
    },
    '5166': {
      id: 5166,
      title: 'v2 tags delete',
      priv: null,
      resource_name: 'v2.tags.delete',
    },
    '5167': {
      id: 5167,
      title: 'v2.paylinks.get',
      priv: null,
      resource_name: 'v2.paylinks.get',
    },
    '5168': {
      id: 5168,
      title: 'v2.paylinks.post',
      priv: null,
      resource_name: 'v2.paylinks.post',
    },
  },
  status: true,
  last_login_ts: 0,
}

export const testUserLotsNotSuperUser: User = {
  id: '11111111-1111-1111-1111-111111111111',
  domain_id: 'domain-11111111111111111',
  contact_id: 'contact-1111111111111111',
  username: 'mickey.mouse@fortispay.com',
  email: 'mickey.mouse@fortispay.com',
  locale: 'en-US',
  tz: 'America/New_York',
  login_attempts: 0,
  current_login_ip: '68.101.143.167',
  requires_new_password: '',
  created_ts: 1347746338,
  modified_ts: 1696527687,
  primary_location_id: '11111111-1111-1111-1111-111111111111',
  sftp_access: true,
  created_user_id: '11111111-1111-1111-1111-111111111111',
  terms_accepted_ts: null,
  terms_agree_ip: null,
  current_date_time: '2023-11-14T15:35:39-0500',
  ui_prefs: {
    entry_page: 'virtualterminal',
    page_size: 15,
    report_export_type: 'csv',
    process_method: 'physical_terminal',
    default_terminal: '11ed65d2b44fec1aab3427d8',
  },
  user_api_key: 'f...1',
  user_hash_key: 'b...7',
  log_api_response_body_ts: null,
  api_only: false,
  user_type_code: 1000,
  status_code: 1,
  terms_condition_code: null,
  location_id: '11111111-1111-1111-1111-111111111111',
  account_number: '9876543210',
  contact_api_id: null,
  company_name: 'OLU test',
  first_name: 'Minnie',
  last_name: 'Mouser',
  address: {
    street: 'Goobs Street',
    city: 'Manns',
    state: 'OH',
    postal_code: '567890',
    country: 'US',
  },
  home_phone: null,
  cell_phone: '1234567890',
  office_phone: null,
  email_trx_receipt: true,
  date_of_birth: '1990-05-15',
  header_message: '',
  active: true,
  contact_c1: null,
  contact_c2: null,
  contact_c3: null,
  parent_id: null,
  office_phone_ext: null,
  header_message_type: 0,
  balance: null,
  locations: [
    {
      id: 'location-222222222222222',
      location_api_id: 'location-222222222222222',
      name: 'Demo Account2',
      office_phone: '2482138999',
      office_ext_phone: '4321',
      fax: '0000000000',
      account_number: '1234567890',
      ticket_hash_key: 'ticket-22222222222222222',
      tz: 'America/New_York',
      created_ts: 1392066931,
      modified_ts: 1417445402,
      location_api_key: 'location-222222222222222',
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11111111-1111-1111-1111-111111111111',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '54aa9ab2-f912-8f38-d9d1-2246649884cc',
      show_contact_notes: true,
      default_ach: null,
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: 'addr2',
        city: 'Metropolis',
        state: 'NY',
        postal_code: '90210',
        country: 'US',
      },
    },
    {
      id: '11ed3e946f2c17e08857bfb1',
      location_api_id: null,
      name: 'Location Calder',
      office_phone: null,
      office_ext_phone: null,
      fax: null,
      account_number: '12345',
      ticket_hash_key: null,
      tz: 'America/New_York',
      created_ts: 1664304275,
      modified_ts: 1668619378,
      location_api_key: null,
      email_reply_to: null,
      branding_domain_id: '11ed3e946f0b2ba2b74248e4',
      branding_domain_url: 'test.zeamster.com',
      parent_id: '',
      location_c1: 'e1234567',
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '11ed65d4cae7cbbcc0b1ba0f',
      show_contact_notes: true,
      default_ach: '11ed40c9a85d444eac4726e3',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: '',
        city: 'Atlanta',
        state: 'GA',
        postal_code: '30339',
        country: 'US',
      },
    },
    {
      id: '11ee2d683e9457be9260359a',
      location_api_id: null,
      name: 'OLUTest Retail 9xib8',
      office_phone: null,
      office_ext_phone: null,
      fax: null,
      account_number: null,
      ticket_hash_key: null,
      tz: 'America/New_York',
      created_ts: 1690563624,
      modified_ts: 1690567499,
      location_api_key: null,
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11111111-1111-1111-1111-111111111111',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: true,
      default_cc: '11ee2d6840fbefeeb11775b2',
      show_contact_notes: true,
      default_ach: '11ee2d6841b944f488c224fb',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: null,
        street2: null,
        city: null,
        state: null,
        postal_code: null,
        country: 'US',
      },
    },
    {
      id: '11111111-1111-1111-1111-111111111111',
      location_api_id: '11111111-1111-1111-1111-111111111111',
      name: 'Demo Account',
      office_phone: '2482138999',
      office_ext_phone: '4321',
      fax: '0000000000',
      account_number: '1234567890',
      ticket_hash_key: 'ticket-11111111111111111',
      tz: 'America/New_York',
      created_ts: 1392066931,
      modified_ts: 1693482076,
      location_api_key: '11111111-1111-1111-1111-111111111111',
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11ed3e946f2c17e08857bfb1',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '11ed3e946f2c17e08857abc4',
      show_contact_notes: true,
      default_ach: '11e608a7d515f1e093242bb2',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: 'addr2',
        city: 'Metropolis',
        state: 'NY',
        postal_code: '123',
        country: 'US',
      },
      '5845': {
        id: 5845,
        title: 'v2 quickinvoices get',
        priv: null,
        resource_name: 'v2.quickinvoices.get',
        created: '2023-09-09 11:23:21',
        last_used_date: null,
        created_ts: 1691495423,
        created_user_id: 'CLI',
        modified_ts: 1691495423,
        modified_user_id: 'CLI',
      },
    },
  ],
  branding_domain_url: 'test.develop.zeamster.com',
  module_access: [1],
  resources: {
    title: 'resources',
    id: 123,
    resource_name: 'resources',
    '5031': {
      id: 5031,
      title: 'Invoices Delete',
      priv: '',
      resource_name: 'v2.invoices.delete',
    },
    '5032': {
      id: 5032,
      title: 'Invoices Get',
      priv: '',
      resource_name: 'v2.invoices.get',
    },
    '5033': {
      id: 5033,
      title: 'Invoices Post',
      priv: '',
      resource_name: 'v2.invoices.post',
    },
    '5034': {
      id: 5034,
      title: 'Invoices Put',
      priv: '',
      resource_name: 'v2.invoices.put',
    },
    '5348': {
      id: 5348,
      title: 'v2admin users get',
      priv: null,
      resource_name: 'v2admin.users.get',
    },
    '5350': {
      id: 5350,
      title: 'v2admin users delete',
      priv: null,
      resource_name: 'v2admin.users.delete',
    },
    '5351': {
      id: 5351,
      title: 'v2admin locations post',
      priv: null,
      resource_name: 'v2admin.locations.post',
    },
    '5352': {
      id: 5352,
      title: 'v2admin locations get',
      priv: null,
      resource_name: 'v2admin.locations.get',
    },
    '5353': {
      id: 5353,
      title: 'v2admin locations put',
      priv: null,
      resource_name: 'v2admin.locations.put',
    },
    '5354': {
      id: 5354,
      title: 'v2admin locations delete',
      priv: null,
      resource_name: 'v2admin.locations.delete',
    },
    '5389': {
      id: 5389,
      title: 'v2 terminalaccountvaults get',
      priv: null,
      resource_name: 'v2.terminalaccountvaults.get',
    },
    '5390': {
      id: 5390,
      title: 'v2 terminalaccountvaults put',
      priv: null,
      resource_name: 'v2.terminalaccountvaults.put',
    },
    '5391': {
      id: 5391,
      title: 'v2 terminalaccountvaults post',
      priv: null,
      resource_name: 'v2.terminalaccountvaults.post',
    },
    '5392': {
      id: 5392,
      title: 'v2 terminalaccountvaults delete',
      priv: null,
      resource_name: 'v2.terminalaccountvaults.delete',
    },
    '5546': {
      id: 5546,
      title: 'v2 productitems post o',
      priv: null,
      resource_name: 'v2.productitems.post.o',
    },
    '5547': {
      id: 5547,
      title: 'v2 productitems post m',
      priv: null,
      resource_name: 'v2.productitems.post.m',
    },
    '5548': {
      id: 5548,
      title: 'v2 productitems post a',
      priv: null,
      resource_name: 'v2.productitems.post.a',
    },
    '5549': {
      id: 5549,
      title: 'v2 productitems post p',
      priv: null,
      resource_name: 'v2.productitems.post.p',
    },
    '5550': {
      id: 5550,
      title: 'v2 productitems post s',
      priv: null,
      resource_name: 'v2.productitems.post.s',
    },
    '5551': {
      id: 5551,
      title: 'v2 productitems put o',
      priv: null,
      resource_name: 'v2.productitems.put.o',
    },
    '5552': {
      id: 5552,
      title: 'v2 productitems put m',
      priv: null,
      resource_name: 'v2.productitems.put.m',
    },
    '5553': {
      id: 5553,
      title: 'v2 productitems put a',
      priv: null,
      resource_name: 'v2.productitems.put.a',
    },
    '5554': {
      id: 5554,
      title: 'v2 productitems put p',
      priv: null,
      resource_name: 'v2.productitems.put.p',
    },
    '5555': {
      id: 5555,
      title: 'v2 productitems put s',
      priv: null,
      resource_name: 'v2.productitems.put.s',
    },
    '5556': {
      id: 5556,
      title: 'v2 productitems delete o',
      priv: null,
      resource_name: 'v2.productitems.delete.o',
    },
    '5681': {
      id: 5681,
      title: 'v2 transactions get split',
      priv: null,
      resource_name: 'v2.transactions.get.split',
    },
    '5767': {
      id: 5767,
      title: 'v1 partner commissions get',
      priv: null,
      resource_name: 'v1.partner.commissions.get',
    },
    '5768': {
      id: 5768,
      title: 'v1 partner commissions get download',
      priv: null,
      resource_name: 'v1.partner.commissions.get.download',
    },
    '5838': {
      id: 5838,
      title: 'v2 merchants get',
      priv: null,
      resource_name: 'v1.partner.merchants.get',
    },
    '5839': {
      id: 5839,
      title: 'v2 merchants get export',
      priv: null,
      resource_name: 'v1.partner.merchants.get.export',
    },
    '5840': {
      id: 5840,
      title: 'v2 reports get',
      priv: null,
      resource_name: 'v2.reports.get',
    },
    '5841': {
      id: 5841,
      title: 'v2 transactions get',
      priv: null,
      resource_name: 'v2.transactions.get',
    },
    '5842': {
      id: 5842,
      title: 'v2 recurrings get',
      priv: null,
      resource_name: 'v2.recurrings.get',
    },
    '5843': {
      id: 5843,
      title: 'v2 transactionbatches get',
      priv: null,
      resource_name: 'v2.transactionbatches.get',
    },
    '5844': {
      id: 5844,
      title: 'v2 contacts get',
      priv: null,
      resource_name: 'v2.contacts.get',
    },
    '5845': {
      id: 5845,
      title: 'v2 quickInvoices get',
      priv: null,
      resource_name: 'v2.quickinvoices.get',
    },
    '5846': {
      id: 5844,
      title: 'v2 contacts put',
      priv: null,
      resource_name: 'v2.contacts.put',
    },
    '5847': {
      id: 5847,
      title: 'v2 transactions post',
      priv: null,
      resource_name: 'v2.transactions.post',
    },
  },
  status: true,
  last_login_ts: 0,
}

export const testUserSuperUserOnly: User = {
  id: '11111111-1111-1111-1111-111111111111',
  domain_id: 'domain-11111111111111111',
  contact_id: 'contact-1111111111111111',
  username: 'walt.disney@fortispay.com',
  email: 'walt.disney@fortispay.com',
  locale: 'en-US',
  tz: 'America/New_York',
  login_attempts: 0,
  current_login_ip: '68.101.143.167',
  requires_new_password: '',
  created_ts: 1347746338,
  modified_ts: 1696527687,
  primary_location_id: '11111111-1111-1111-1111-111111111111',
  sftp_access: true,
  created_user_id: '11111111-1111-1111-1111-111111111111',
  terms_accepted_ts: null,
  terms_agree_ip: null,
  current_date_time: '2023-11-14T15:35:39-0500',
  ui_prefs: {
    entry_page: 'virtualterminal',
    page_size: 15,
    report_export_type: 'csv',
    process_method: 'virtual_terminal',
    default_terminal: '11ed65d2b44fec1aab3427d8',
  },
  user_api_key: 'f...1',
  user_hash_key: 'b...7',
  log_api_response_body_ts: null,
  api_only: false,
  user_type_code: 1000,
  status_code: 1,
  terms_condition_code: null,
  location_id: '11111111-1111-1111-1111-111111111111',
  account_number: '9876543210',
  contact_api_id: null,
  company_name: 'OLU test',
  first_name: 'Minnie',
  last_name: 'Mouser',
  address: {
    street: 'Goobs Street',
    city: 'Manns',
    state: 'OH',
    postal_code: '567890',
    country: 'US',
  },
  home_phone: null,
  cell_phone: '2482138999',
  office_phone: null,
  email_trx_receipt: true,
  date_of_birth: '1990-06-16',
  header_message: '',
  active: true,
  contact_c1: null,
  contact_c2: null,
  contact_c3: null,
  parent_id: null,
  office_phone_ext: null,
  header_message_type: 0,
  balance: null,
  locations: [
    {
      id: 'location-222222222222222',
      location_api_id: 'location-222222222222222',
      name: 'Demo Account2',
      office_phone: '2482138999',
      office_ext_phone: '4321',
      fax: '0000000000',
      account_number: '1234567890',
      ticket_hash_key: 'ticket-22222222222222222',
      tz: 'America/New_York',
      created_ts: 1392066931,
      modified_ts: 1417445402,
      location_api_key: 'location-222222222222222',
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11111111-1111-1111-1111-111111111111',
      parent_name: 'Parent Name 1',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '54aa9ab2-f912-8f38-d9d1-2246649884cc',
      show_contact_notes: true,
      default_ach: null,
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: 'addr2',
        city: 'Metropolis',
        state: 'NY',
        postal_code: '90210',
        country: 'US',
      },
    },
    {
      id: '11ed3e946f2c17e08857bfb1',
      location_api_id: null,
      name: 'Location Calder',
      office_phone: null,
      office_ext_phone: null,
      fax: null,
      account_number: '12345',
      ticket_hash_key: null,
      tz: 'America/New_York',
      created_ts: 1664304275,
      modified_ts: 1668619378,
      location_api_key: null,
      email_reply_to: null,
      branding_domain_id: '11ed3e946f0b2ba2b74248e4',
      branding_domain_url: 'test.zeamster.com',
      parent_id: '22222222',
      parent_name: 'Parent Name 2',
      location_c1: 'e1234567',
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '11ed65d4cae7cbbcc0b1ba0f',
      show_contact_notes: true,
      default_ach: '11ed40c9a85d444eac4726e3',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'agent',
      address: {
        street1: '123 Main Street',
        street2: '',
        city: 'Atlanta',
        state: 'GA',
        postal_code: '30339',
        country: 'US',
      },
    },
    {
      id: '11ee2d683e9457be9260359a',
      location_api_id: null,
      name: 'OLUTest Retail 9xib8',
      office_phone: null,
      office_ext_phone: null,
      fax: null,
      account_number: null,
      ticket_hash_key: null,
      tz: 'America/New_York',
      created_ts: 1690563624,
      modified_ts: 1690567499,
      location_api_key: null,
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '33333333-3333-3333-3333-333333333333',
      parent_name: 'Parent Name 3',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: true,
      default_cc: '11ee2d6840fbefeeb11775b2',
      show_contact_notes: true,
      default_ach: '11ee2d6841b944f488c224fb',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: null,
        street2: null,
        city: null,
        state: null,
        postal_code: null,
        country: 'US',
      },
    },
    {
      id: '11111111-1111-1111-1111-111111111111',
      location_api_id: '11111111-1111-1111-1111-111111111111',
      name: 'Demo Account',
      office_phone: '2482138999',
      office_ext_phone: '4321',
      fax: '0000000000',
      account_number: '1234567890',
      ticket_hash_key: 'ticket-11111111111111111',
      tz: 'America/New_York',
      created_ts: 1392066931,
      modified_ts: 1693482076,
      location_api_key: '11111111-1111-1111-1111-111111111111',
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11ed3e946f2c17e08857bfb1',
      parent_name: 'Parent Name 4',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '11ed3e946f2c17e08857abc4',
      show_contact_notes: true,
      default_ach: '11e608a7d515f1e093242bb2',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: 'addr2',
        city: 'Metropolis',
        state: 'NY',
        postal_code: '123',
        country: 'US',
      },
    },
  ],
  branding_domain_url: 'test.develop.zeamster.com',
  module_access: [1],
  resources: {
    title: 'resources',
    id: 123,
    resource_name: 'resources',
    '1': {
      id: 1,
      title: 'Superuser',
      priv: '',
      resource_name: '*',
    },
  },
  status: true,
  last_login_ts: 0,
}

export const testUserInvoiceOnly: User = {
  id: '11111111-1111-1111-1111-111111111111',
  domain_id: 'domain-11111111111111111',
  contact_id: 'contact-1111111111111111',
  username: 'goofy.dog@fortispay.com',
  email: 'goofy.dog@fortispay.com',
  locale: 'en-US',
  tz: 'America/New_York',
  login_attempts: 0,
  current_login_ip: '68.101.143.167',
  requires_new_password: '',
  created_ts: 1347746338,
  modified_ts: 1696527687,
  primary_location_id: '11111111-1111-1111-1111-111111111111',
  sftp_access: true,
  created_user_id: '11111111-1111-1111-1111-111111111111',
  terms_accepted_ts: null,
  terms_agree_ip: null,
  current_date_time: '2023-11-14T15:35:39-0500',
  ui_prefs: {
    entry_page: 'virtualterminal',
    page_size: 15,
    report_export_type: 'csv',
    process_method: 'physical_terminal',
    default_terminal: '11ed65d2b44fec1aab3427d8',
  },
  user_api_key: 'f...1',
  user_hash_key: 'b...7',
  log_api_response_body_ts: null,
  api_only: false,
  user_type_code: 1000,
  status_code: 1,
  terms_condition_code: null,
  location_id: '11111111-1111-1111-1111-111111111111',
  account_number: '9876543210',
  contact_api_id: null,
  company_name: 'OLU test',
  first_name: 'Minnie',
  last_name: 'Mouser',
  address: {
    street: 'Goobs Street',
    city: 'Manns',
    state: 'OH',
    postal_code: '567890',
    country: 'US',
  },
  home_phone: null,
  cell_phone: null,
  office_phone: null,
  email_trx_receipt: true,
  date_of_birth: '1990-05-15',
  header_message: '',
  active: true,
  contact_c1: null,
  contact_c2: null,
  contact_c3: null,
  parent_id: null,
  office_phone_ext: null,
  header_message_type: 0,
  balance: null,
  locations: [
    {
      id: 'location-222222222222222',
      location_api_id: 'location-222222222222222',
      name: 'Demo Account2',
      office_phone: '2482138999',
      office_ext_phone: '4321',
      fax: '0000000000',
      account_number: '1234567890',
      ticket_hash_key: 'ticket-22222222222222222',
      tz: 'America/New_York',
      created_ts: 1392066931,
      modified_ts: 1417445402,
      location_api_key: 'location-222222222222222',
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11111111-1111-1111-1111-111111111111',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '54aa9ab2-f912-8f38-d9d1-2246649884cc',
      show_contact_notes: true,
      default_ach: null,
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: 'addr2',
        city: 'Metropolis',
        state: 'NY',
        postal_code: '90210',
        country: 'US',
      },
    },
    {
      id: '11ed3e946f2c17e08857bfb1',
      location_api_id: null,
      name: 'Location Calder',
      office_phone: null,
      office_ext_phone: null,
      fax: null,
      account_number: '12345',
      ticket_hash_key: null,
      tz: 'America/New_York',
      created_ts: 1664304275,
      modified_ts: 1668619378,
      location_api_key: null,
      email_reply_to: null,
      branding_domain_id: '11ed3e946f0b2ba2b74248e4',
      branding_domain_url: 'test.zeamster.com',
      parent_id: '',
      location_c1: 'e1234567',
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '11ed65d4cae7cbbcc0b1ba0f',
      show_contact_notes: true,
      default_ach: '11ed40c9a85d444eac4726e3',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: '',
        city: 'Atlanta',
        state: 'GA',
        postal_code: '30339',
        country: 'US',
      },
    },
    {
      id: '11ee2d683e9457be9260359a',
      location_api_id: null,
      name: 'OLUTest Retail 9xib8',
      office_phone: null,
      office_ext_phone: null,
      fax: null,
      account_number: null,
      ticket_hash_key: null,
      tz: 'America/New_York',
      created_ts: 1690563624,
      modified_ts: 1690567499,
      location_api_key: null,
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11111111-1111-1111-1111-111111111111',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: true,
      default_cc: '11ee2d6840fbefeeb11775b2',
      show_contact_notes: true,
      default_ach: '11ee2d6841b944f488c224fb',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: null,
        street2: null,
        city: null,
        state: null,
        postal_code: null,
        country: 'US',
      },
    },
    {
      id: '11111111-1111-1111-1111-111111111111',
      location_api_id: '11111111-1111-1111-1111-111111111111',
      name: 'Demo Account',
      office_phone: '2482138999',
      office_ext_phone: '4321',
      fax: '0000000000',
      account_number: '1234567890',
      ticket_hash_key: 'ticket-11111111111111111',
      tz: 'America/New_York',
      created_ts: 1392066931,
      modified_ts: 1693482076,
      location_api_key: '11111111-1111-1111-1111-111111111111',
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11ed3e946f2c17e08857bfb1',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '11ed3e946f2c17e08857abc4',
      show_contact_notes: true,
      default_ach: '11e608a7d515f1e093242bb2',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: 'addr2',
        city: 'Metropolis',
        state: 'NY',
        postal_code: '123',
        country: 'US',
      },
    },
  ],
  branding_domain_url: 'test.develop.zeamster.com',
  module_access: [1],
  resources: {
    title: 'resources',
    id: 123,
    resource_name: 'resources',
    '5031': {
      id: 5031,
      title: 'Invoices Delete',
      priv: '',
      resource_name: 'v2.invoices.delete',
    },
    '5032': {
      id: 5032,
      title: 'Invoices Get',
      priv: '',
      resource_name: 'v2.invoices.get',
    },
    '5033': {
      id: 5033,
      title: 'Invoices Post',
      priv: '',
      resource_name: 'v2.invoices.post',
    },
    '5034': {
      id: 5034,
      title: 'Invoices Put',
      priv: '',
      resource_name: 'v2.invoices.put',
    },
  },
  status: true,
  last_login_ts: 0,
}

export const testUserNone: User = {
  id: '11111111-1111-1111-1111-111111111111',
  domain_id: 'domain-11111111111111111',
  contact_id: 'contact-1111111111111111',
  username: 'daisy.dog@fortispay.com',
  email: 'daisy.dog@fortispay.com',
  locale: 'en-US',
  tz: 'America/New_York',
  login_attempts: 0,
  current_login_ip: '68.101.143.167',
  requires_new_password: '',
  created_ts: 1347746338,
  modified_ts: 1696527687,
  primary_location_id: '11111111-1111-1111-1111-111111111111',
  sftp_access: true,
  created_user_id: '11111111-1111-1111-1111-111111111111',
  terms_accepted_ts: null,
  terms_agree_ip: null,
  current_date_time: '2023-11-14T15:35:39-0500',
  ui_prefs: {
    entry_page: 'virtualterminal',
    page_size: 15,
    report_export_type: 'csv',
    process_method: 'physical_terminal',
    default_terminal: '11ed65d2b44fec1aab3427d8',
  },
  user_api_key: 'f...1',
  user_hash_key: 'b...7',
  log_api_response_body_ts: null,
  api_only: false,
  user_type_code: 1000,
  status_code: 1,
  terms_condition_code: null,
  location_id: '11111111-1111-1111-1111-111111111111',
  account_number: '9876543210',
  contact_api_id: null,
  company_name: 'OLU test',
  first_name: 'Minnie',
  last_name: 'Mouser',
  address: {
    street: 'Goobs Street',
    city: 'Manns',
    state: 'OH',
    postal_code: '567890',
    country: 'US',
  },
  home_phone: null,
  cell_phone: null,
  office_phone: null,
  email_trx_receipt: true,
  date_of_birth: '1990-06-16',
  header_message: '',
  active: true,
  contact_c1: null,
  contact_c2: null,
  contact_c3: null,
  parent_id: null,
  office_phone_ext: null,
  header_message_type: 0,
  balance: null,
  locations: [
    {
      id: 'location-222222222222222',
      location_api_id: 'location-222222222222222',
      name: 'Demo Account2',
      office_phone: '2482138999',
      office_ext_phone: '4321',
      fax: '0000000000',
      account_number: '1234567890',
      ticket_hash_key: 'ticket-22222222222222222',
      tz: 'America/New_York',
      created_ts: 1392066931,
      modified_ts: 1417445402,
      location_api_key: 'location-222222222222222',
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11111111-1111-1111-1111-111111111111',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '54aa9ab2-f912-8f38-d9d1-2246649884cc',
      show_contact_notes: true,
      default_ach: null,
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: 'addr2',
        city: 'Metropolis',
        state: 'NY',
        postal_code: '90210',
        country: 'US',
      },
    },
    {
      id: '11ed3e946f2c17e08857bfb1',
      location_api_id: null,
      name: 'Location Calder',
      office_phone: null,
      office_ext_phone: null,
      fax: null,
      account_number: '12345',
      ticket_hash_key: null,
      tz: 'America/New_York',
      created_ts: 1664304275,
      modified_ts: 1668619378,
      location_api_key: null,
      email_reply_to: null,
      branding_domain_id: '11ed3e946f0b2ba2b74248e4',
      branding_domain_url: 'test.zeamster.com',
      parent_id: '',
      location_c1: 'e1234567',
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '11ed65d4cae7cbbcc0b1ba0f',
      show_contact_notes: true,
      default_ach: '11ed40c9a85d444eac4726e3',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: '',
        city: 'Atlanta',
        state: 'GA',
        postal_code: '30339',
        country: 'US',
      },
    },
    {
      id: '11ee2d683e9457be9260359a',
      location_api_id: null,
      name: 'OLUTest Retail 9xib8',
      office_phone: null,
      office_ext_phone: null,
      fax: null,
      account_number: null,
      ticket_hash_key: null,
      tz: 'America/New_York',
      created_ts: 1690563624,
      modified_ts: 1690567499,
      location_api_key: null,
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11111111-1111-1111-1111-111111111111',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: true,
      default_cc: '11ee2d6840fbefeeb11775b2',
      show_contact_notes: true,
      default_ach: '11ee2d6841b944f488c224fb',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: null,
        street2: null,
        city: null,
        state: null,
        postal_code: null,
        country: 'US',
      },
    },
    {
      id: '11111111-1111-1111-1111-111111111111',
      location_api_id: '11111111-1111-1111-1111-111111111111',
      name: 'Demo Account',
      office_phone: '2482138999',
      office_ext_phone: '4321',
      fax: '0000000000',
      account_number: '1234567890',
      ticket_hash_key: 'ticket-11111111111111111',
      tz: 'America/New_York',
      created_ts: 1392066931,
      modified_ts: 1693482076,
      location_api_key: '11111111-1111-1111-1111-111111111111',
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11ed3e946f2c17e08857bfb1',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '11ed3e946f2c17e08857abc4',
      show_contact_notes: true,
      default_ach: '11e608a7d515f1e093242bb2',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: 'addr2',
        city: 'Metropolis',
        state: 'NY',
        postal_code: '123',
        country: 'US',
      },
    },
  ],
  branding_domain_url: 'test.develop.zeamster.com',
  module_access: [1],
  resources: undefined,
  status: true,
  last_login_ts: 0,
}

export const userNone: User = {
  id: '11111111-1111-1111-1111-111111111111',
  domain_id: 'domain-11111111111111111',
  contact_id: 'contact-1111111111111111',
  username: 'daisy.dog@fortispay.com',
  email: 'daisy.dog@fortispay.com',
  locale: 'en-US',
  tz: 'America/New_York',
  login_attempts: 0,
  current_login_ip: '68.101.143.167',
  requires_new_password: '',
  created_ts: 1347746338,
  modified_ts: 1696527687,
  primary_location_id: '11111111-1111-1111-1111-111111111111',
  sftp_access: false,
  created_user_id: '11111111-1111-1111-1111-111111111111',
  terms_accepted_ts: null,
  terms_agree_ip: null,
  current_date_time: '2023-11-14T15:35:39-0500',
  ui_prefs: {
    entry_page: 'virtualterminal',
    page_size: 15,
    report_export_type: 'csv',
    process_method: 'physical_terminal',
    default_terminal: '11ed65d2b44fec1aab3427d8',
  },
  user_api_key: 'f...1',
  user_hash_key: 'b...7',
  log_api_response_body_ts: null,
  api_only: false,
  user_type_code: 1000,
  status_code: 1,
  terms_condition_code: null,
  location_id: '11111111-1111-1111-1111-111111111111',
  account_number: '9876543210',
  contact_api_id: null,
  company_name: 'OLU test',
  first_name: 'Minnie',
  last_name: 'Mouser',
  address: {
    street: 'Goobs Street',
    city: 'Manns',
    state: 'OH',
    postal_code: '567890',
    country: 'US',
  },
  home_phone: null,
  cell_phone: null,
  office_phone: null,
  email_trx_receipt: true,
  date_of_birth: '1990-06-16',
  header_message: '',
  active: true,
  contact_c1: null,
  contact_c2: null,
  contact_c3: null,
  parent_id: null,
  office_phone_ext: null,
  header_message_type: 0,
  balance: null,
  locations: [
    {
      id: 'location-222222222222222',
      location_api_id: 'location-222222222222222',
      name: 'Demo Account2',
      office_phone: '2482138999',
      office_ext_phone: '4321',
      fax: '0000000000',
      account_number: '1234567890',
      ticket_hash_key: 'ticket-22222222222222222',
      tz: 'America/New_York',
      created_ts: 1392066931,
      modified_ts: 1417445402,
      location_api_key: 'location-222222222222222',
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11111111-1111-1111-1111-111111111111',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '54aa9ab2-f912-8f38-d9d1-2246649884cc',
      show_contact_notes: true,
      default_ach: null,
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: 'addr2',
        city: 'Metropolis',
        state: 'NY',
        postal_code: '90210',
        country: 'US',
      },
    },
    {
      id: '11ed3e946f2c17e08857bfb1',
      location_api_id: null,
      name: 'Location Calder',
      office_phone: null,
      office_ext_phone: null,
      fax: null,
      account_number: '12345',
      ticket_hash_key: null,
      tz: 'America/New_York',
      created_ts: 1664304275,
      modified_ts: 1668619378,
      location_api_key: null,
      email_reply_to: null,
      branding_domain_id: '11ed3e946f0b2ba2b74248e4',
      branding_domain_url: 'test.zeamster.com',
      parent_id: '',
      location_c1: 'e1234567',
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '11ed65d4cae7cbbcc0b1ba0f',
      show_contact_notes: true,
      default_ach: '11ed40c9a85d444eac4726e3',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: '',
        city: 'Atlanta',
        state: 'GA',
        postal_code: '30339',
        country: 'US',
      },
    },
    {
      id: '11ee2d683e9457be9260359a',
      location_api_id: null,
      name: 'OLUTest Retail 9xib8',
      office_phone: null,
      office_ext_phone: null,
      fax: null,
      account_number: null,
      ticket_hash_key: null,
      tz: 'America/New_York',
      created_ts: 1690563624,
      modified_ts: 1690567499,
      location_api_key: null,
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11111111-1111-1111-1111-111111111111',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: true,
      default_cc: '11ee2d6840fbefeeb11775b2',
      show_contact_notes: true,
      default_ach: '11ee2d6841b944f488c224fb',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: null,
        street2: null,
        city: null,
        state: null,
        postal_code: null,
        country: 'US',
      },
    },
    {
      id: '11111111-1111-1111-1111-111111111111',
      location_api_id: '11111111-1111-1111-1111-111111111111',
      name: 'Demo Account',
      office_phone: '2482138999',
      office_ext_phone: '4321',
      fax: '0000000000',
      account_number: '1234567890',
      ticket_hash_key: 'ticket-11111111111111111',
      tz: 'America/New_York',
      created_ts: 1392066931,
      modified_ts: 1693482076,
      location_api_key: '11111111-1111-1111-1111-111111111111',
      email_reply_to: null,
      branding_domain_id: 'domain-11111111111111111',
      branding_domain_url: 'test.develop.zeamster.com',
      parent_id: '11ed3e946f2c17e08857bfb1',
      location_c1: null,
      location_c2: null,
      location_c3: null,
      contact_email_trx_receipt_default: false,
      default_cc: '11ed3e946f2c17e08857abc4',
      show_contact_notes: true,
      default_ach: '11e608a7d515f1e093242bb2',
      show_contact_files: true,
      created_user_id: '11111111-1111-1111-1111-111111111111',
      location_type: 'merchant',
      address: {
        street1: '123 Main Street',
        street2: 'addr2',
        city: 'Metropolis',
        state: 'NY',
        postal_code: '123',
        country: 'US',
      },
    },
  ],
  branding_domain_url: 'test.develop.zeamster.com',
  module_access: [1],
  resources: undefined,
  status: true,
  last_login_ts: 1347746338,
}
