import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useRef, useState } from 'react'
import { useForm, useWatch, Controller, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { Box, Link } from '@mui/material'

import { api } from '@shared/api'
import { Input, Button } from '@shared/components'
import { useNotification } from '@shared/hooks'

import { LoginLayout } from '../../component/LoginLayout'

const useStyles = tss.withName('ForgotPassword').create(({ theme }) => ({
  navigationLink: {
    color: `${theme?.palette['network-blue']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '600 !important',
    lineHeight: '20px !important',
  },
}))

interface FormData {
  email: string
}

export default function ForgotPassword() {
  const { classes } = useStyles()
  const { setNotification } = useNotification()
  const { t } = useTranslation()

  const inputRef = useRef(null)

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [serverErrors, setServerErrors] = useState<{
    email?: string
  }>({})

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('common.validations.invalid-email'))
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        t('common.validations.invalid-email')
      ),
  })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  })

  const email = useWatch({ name: 'email', control })

  const enableSendCode = email.length > 0

  const hasErrors = serverErrors.email?.length > 0

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  useEffect(() => {
    if (email.length === 0) {
      setServerErrors({})
    }
  }, [email])

  const onSubmit: SubmitHandler<FormData> = async () => {
    setIsLoading(true)
    try {
      await api.service('public/users').create_code(email)

      navigate(`/login/enter-code?email=${email}`)

      setNotification({
        type: 'success',
        label: t('login.please-check-your-email'),
      })
    } catch (error) {
      setNotification({
        type: 'error',
        label: error.message,
      })

      setServerErrors({
        email: t('common.internal-error'),
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <LoginLayout
      title={t('common.password-reset')}
      description={
        <>
          {`${t('login.forgot-password-text-1')} `}
          <strong>{t('login.password-reset-code')}</strong>
          {` ${t('login.forgot-password-text-2')}`}
        </>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              testId="forgotpassword-email-input"
              label={t('common.email')}
              placeholder={t('login.enter-email-placeholder')}
              error={hasErrors}
              helperText={serverErrors.email}
              style={{
                marginBottom: '32px !important',
              }}
              ref={inputRef}
            />
          )}
        />

        <Button
          label={t('common.send-code')}
          type="submit"
          disabled={!enableSendCode || !isValid}
          testId="sendcode-button"
          isLoading={isLoading}
          style={{
            marginBottom: '32px !important',
          }}
        />
      </form>

      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Link
          className={classes.navigationLink}
          underline="none"
          href=""
          onClick={() => navigate('/login')}
        >
          {t('login.back-to-sign-in')}
        </Link>
      </Box>
    </LoginLayout>
  )
}
