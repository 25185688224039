import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useRef, useState } from 'react'
import { useForm, useWatch, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

import { api } from '@shared/api'
import { useNotification } from '@shared/hooks'

import { LoginLayout } from '../../component/LoginLayout'
import { PasswordForm } from '../../component/PasswordForm'

interface FormData {
  newPassword: string
  confirmNewPassword: string
}

export default function CreateNewPassword() {
  const { t } = useTranslation()
  const { setNotification } = useNotification()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const email = searchParams.get('email')
  const code = searchParams.get('code')
  const reason = searchParams.get('reason')

  const onSubmit: SubmitHandler<FormData> = async ({
    newPassword,
  }: {
    newPassword: string
  }) => {
    try {
      const status = await api
        .service('public/users')
        .update_password(email, code, newPassword)

      if (!status.ok) {
        setNotification({
          label: t('login.validations.couldnt-reset-password'),
          type: 'error',
        })
        return
      } else {
        navigate(`/login/password-created`)
      }
    } catch (error) {
      setNotification({
        label: error.message,
        type: 'error',
      })
    }
  }

  return (
    <LoginLayout
      title={
        reason === 'expired'
          ? t('login.password-expired-title')
          : t('login.create-new-password-title')
      }
      description={
        <>
          {`${
            reason === 'expired'
              ? t('login.create-new-password-text-1')
              : t('login.create-new-password-text-2')
          } `}
          <strong>{email}</strong>
          {`. ${t('login.create-new-password-text-3')}`}
        </>
      }
    >
      <PasswordForm onSubmit={onSubmit} />
    </LoginLayout>
  )
}
