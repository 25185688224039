import { useEffect, useMemo, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Routes } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'

import AddressablePage from '@shared/addressable-page-manager/src/AddressablePage'
import AddressablePageManager from '@shared/addressable-page-manager/src/AddressablePageManager'
import AppTheme from '@shared/design'
import {
  FtpPortalHubCommunicationProvider,
  NotificationProvider,
} from '@shared/hooks'
import { userNone } from '@shared/mocks/mockUsers'

import i18n from './i18n'
import CreateNewPassword from './pages/create-new-password/CreateNewPassword'
import ForgotPassword from './pages/forgot-password/ForgotPassword'
import NewPasswordSuccessfullyCreated from './pages/new-password-successfully-created/NewPasswordSuccessfullyCreated'
import PasswordResetCode from './pages/password-reset-code/PasswordResetCode'
import { SetPassword } from './pages/set-password/SetPassword'
import SignIn from './pages/signin/SignIn'
import { WelcomeUser } from './pages/welcome-user/WelcomeUser'

const FtpLogin = () => {
  const [addressablePageManager, setAddressablePageManager] =
    useState<AddressablePageManager>()

  const pages = useMemo(
    () => [
      new AddressablePage('Login', <SignIn />, [], '/login', undefined, true),
      new AddressablePage(
        'ForgotPassword',
        <ForgotPassword />,
        [],
        '/login/forgot-password',
        undefined,
        true
      ),
      new AddressablePage(
        'PasswordResetCode',
        <PasswordResetCode />,
        [],
        '/login/enter-code',
        undefined,
        true
      ),
      new AddressablePage(
        'CreateNewPassword',
        <CreateNewPassword />,
        [],
        '/login/create-password',
        undefined,
        true
      ),
      new AddressablePage(
        'NewPasswordSuccessfullyCreated',
        <NewPasswordSuccessfullyCreated />,
        [],
        '/login/password-created',
        undefined,
        true
      ),
      new AddressablePage(
        'WelcomeUser',
        <WelcomeUser />,
        [],
        '/login/user-verification/:userVerificationId/welcome',
        undefined,
        true
      ),
      new AddressablePage(
        'SetPassword',
        <SetPassword />,
        [],
        '/login/user-verification/:userVerificationId/set-password',
        undefined,
        true
      ),
    ],
    []
  )

  useEffect(() => {
    setAddressablePageManager(new AddressablePageManager(pages, userNone))
  }, [])

  if (!addressablePageManager) return null
  return (
    <BrowserRouter>
      <FtpPortalHubCommunicationProvider>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={AppTheme}>
            <NotificationProvider>
              <Routes>
                {addressablePageManager.renderAllAddressablePages()}
              </Routes>
            </NotificationProvider>
          </ThemeProvider>
        </I18nextProvider>
      </FtpPortalHubCommunicationProvider>
    </BrowserRouter>
  )
}

export default FtpLogin
