import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useRef, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { Button, InputPassword, PasswordRules } from '@shared/components'

export interface PasswordFormData {
  newPassword: string
  confirmNewPassword: string
}

interface PasswordFormProps {
  onSubmit: (data: PasswordFormData) => void
}

export const PasswordForm: FC<PasswordFormProps> = ({ onSubmit }) => {
  const inputRef = useRef(null)
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required(t('login.validations.enter-a-new-password'))
      .min(8)
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$#!%*?&]+$/),
    confirmNewPassword: yup
      .string()
      .oneOf(
        [yup.ref('newPassword')],
        t('login.validations.password-doesnt-match')
      ),
  })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const newPassword = useWatch({ name: 'newPassword', control })

  const submit = async (data: PasswordFormData) => {
    setIsLoading(true)
    await onSubmit(data)
    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Controller
        name="newPassword"
        control={control}
        render={({ field }) => (
          <InputPassword
            {...field}
            testId="newpassword-input"
            label={t('login.new-password')}
            placeholder={t('login.new-password-placeholder')}
            ref={inputRef}
          />
        )}
      />

      <PasswordRules password={newPassword} />

      <Controller
        name="confirmNewPassword"
        control={control}
        render={({ field }) => (
          <InputPassword
            {...field}
            testId="confirmnewpassword-input"
            label={t('login.confirm-new-password')}
            placeholder={t('login.confirm-new-password-placeholder')}
            style={{ marginTop: '24px', marginBottom: '24px' }}
          />
        )}
      />

      <Button
        label={t('login.save-new-password')}
        type="submit"
        disabled={!isValid}
        testId="savenewpassword-button"
        isLoading={isLoading}
        style={{ marginBottom: '24px' }}
      />
    </form>
  )
}
