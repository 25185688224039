import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '@shared/components'

import { LoginLayout } from '../../component/LoginLayout'

export default function NewPasswordSuccessfullyCreated() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <LoginLayout
      title={t('login.new-password-created-title')}
      description={t('login.new-password-created-text')}
    >
      <Button
        label={t('login.sign-in')}
        testId="sign-in-button"
        style={{
          marginBottom: '28px !important',
        }}
        onClick={() => navigate('/login')}
      />
    </LoginLayout>
  )
}
