import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Link,
  Typography,
} from '@mui/material'

import logoFortis from '../assets/imgs/fortis-logo.png'

interface LoginLayoutProps {
  title: string
  description: string | React.ReactNode
  children: React.ReactNode
}

const useStyles = tss.withName('SignIn').create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '3rem',
    paddingBottom: '3rem',
  },
  card: {
    marginTop: '70px',
    width: '80%',
    maxWidth: '570px',
    padding: '40px',
    borderRadius: '6px',
    boxShadow: '0px 7.504px 31.267px 0px rgba(0, 0, 0, 0.10) !important',
  },
  title: {
    color: `${theme?.palette['neutral-900']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '24px !important',
    fontWeight: '600 !important',
    lineHeight: '40.022px !important',
    marginBottom: '10px !important',
  },
  subtitle: {
    color: `${theme?.palette['neutral-600']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '30.017px !important',
    marginBottom: '32px !important',
  },
  links: {
    color: `${theme?.palette['neutral-700']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '600 !important',
  },
}))

export const LoginLayout: FC<LoginLayoutProps> = ({
  title,
  description,
  children,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.container}>
      <img src={logoFortis} alt="fortis-logo" width="215" />

      <Card className={classes.card}>
        <Typography className={classes.title} data-testid="login-title">
          {title}
        </Typography>
        <Typography className={classes.subtitle} data-testid="login-subtitle">
          {description}
        </Typography>

        {children}

        <Box style={{ display: 'flex', marginTop: '85px' }}>
          <Link
            className={classes.links}
            href="https://fortispay.com/support/"
            target="_blank"
            underline="hover"
            style={{ marginRight: '57px' }}
          >
            {t('common.support')}
          </Link>
          <Link
            className={classes.links}
            href="https://fortispay.com/privacy-and-terms/"
            target="_blank"
            underline="hover"
          >
            {t('common.privacy-policy')}
          </Link>
        </Box>
      </Card>
    </Box>
  )
}
