import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { navigateToUrl } from 'single-spa'
import { tss } from 'tss-react/mui'

import { CardActions } from '@mui/material'

import { Button } from '@shared/components'

import { LoginLayout } from '../../component/LoginLayout'

const useStyles = tss.withName('WelcomeUser').create(({ theme }) => ({
  button: {
    width: '100%',
  },
}))

export const WelcomeUser: FC = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const { userVerificationId } = useParams()
  const hash = searchParams.get('hash')

  if (!hash || !userVerificationId) {
    navigateToUrl('/login')
  }

  return (
    <LoginLayout
      title={t('login.welcome-user')}
      description={t('login.welcome-description')}
    >
      <CardActions>
        <Button
          label={t('login.activate-account')}
          type="button"
          testId="activate-button"
          className={classes.button}
          onClick={() =>
            navigate(
              `/login/user-verification/${userVerificationId}/set-password?hash=${hash}`
            )
          }
        />
      </CardActions>
    </LoginLayout>
  )
}
